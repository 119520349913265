























































import { appName } from '@/env';
import { dispatchLogIn } from '@/store/main/actions';
import { readLogInError } from '@/store/main/getters';
import { Component, Vue, Watch } from 'vue-property-decorator';
@Component
export default class Login extends Vue {
  public appName = appName;
  public email: string = '';
  public password: string = '';

  public async submit() {
    dispatchLogIn(this.$store, {
      username: this.email,
      password: this.password,
    });
  }

  public get logInError() {
    return readLogInError(this.$store);
  }
}
